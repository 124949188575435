<template>
    <footer class="footer mt-5 text-center" >
        <div class="container py-5">
            <div class="row">
                <div class="col-md-4">
                    <h5>About Us</h5>
                    <p>Welcome to Lightingers.net, where innovation meets precision in the realm of technology.
                        Founded with a vision to redefine boundaries and set new benchmarks in the tech industry.
                    </p>
                </div>
                <div class="col-md-4">
                    <h5>Quick Links</h5>
                    <ul class="list-unstyled">
                        <li><a href="#home">Home</a></li>
                        <li><a href="#Services">Services</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#Contact">Contact</a></li>
                    </ul>
                </div>
                <div class="col-md-4 ">
                    <h5 class="">Quick Contact</h5>
                    <img :src="qrcode" alt="" class="qrcode">
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <p>&copy; 2024 light Fingers. All Rights Reserved. <span><i class="bi bi-fingerprint isSpecialText"></i></span></p>
                </div>
                <div class="col-md-6 ">
                    <ul class="list-inline ">
                        <li class="list-inline-item text-muted"><a href="#">Privacy Policy</a></li>
                        <li class="list-inline-item"><a href="#">Terms of Service</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'pageFooter',
    data(){
        return{
            qrcode:'../assets/images/wa.link_vwlkxq.png'
        }
    }
}
</script>

<style scoped>
.footer {
    background: radial-gradient(circle at 24.1% 68.8%, rgb(19 21 25) 0%, rgb(21 19 19) 99.4%);
    color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;

}

.footer a {
    color: white;
}

.footer a:hover {
    text-decoration: none;
    color: #ccc;
}
.qrcode{
    max-width: 100%;
    width: 20%;
    height: auto;
}
</style>