<template>
  <div class="p-4 container-fluid main-section" data-aos="fade-in" id="Services">
    <div class="row service-section">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-section p-3"
        
      >
        <h1 data-aos="fade-up">
          Elevate Your <span class="isSpecialText">Online Presence</span> with Our
          Expertise
        </h1>
        <p data-aos="fade-up">
          Looking for a seamless digital solution? Look no further. Our team specializes
          in crafting stunning websites with Responsive Web Design, ensuring your site
          looks perfect on any device. From captivating Frontend Development to robust
          Backend Development, we've got you covered. Need a standout online store? Our
          E-commerce Solutions will exceed your expectations. Need a Landing Page that
          converts? We excel in creating engaging, high-conversion Landing Pages that
          drive results. Choose us for your digital needs, and watch your online presence
          soar
        </p>
      </div>

      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="fade-up-right">
        <img :src="man" alt="" class="bigLogo">
      </div>


      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 hasHover serviceList text-light"
        
      >
        <h3 data-aos="fade-up" class="isTitle">What We <span class="isSpecialText">Do?</span></h3>
        <div v-for="(x, index) in servises" :key="index" data-aos="fade-up">
          <p class="">
            <span><i class="bi bi-check2-circle isSpecialText"></i></span>
            {{ x }}
          </p>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhatWeDo",
  data() {
    return {
      servises: [
        "Responsive Web Design",
        "Frontend Development",
        "Backend Development",
        "Landing Pages Creation",
        "E-commerce Solutions",
        "Wordpress Custom Plugins",
        "Front End Redesign",
        
      ],
      man: "../assets/logo/lightFingers logo white.png",
    };
  },
};
</script>
