<template>
    <div class="p-4 container-fluid main-section" data-aos="fade-in" id="portfolio">
        <div class="row service-section">
            <h1 data-aos="fade-up">
                A <span class="isSpecialText">Gist</span> of what we can do
            </h1>
            <div class="col-6 col-sm-12 col-md-3 col-lg-3 col-xl-3 text-section p-3 text-light" v-for="portfolio in portfolios" :key="portfolio.id" >
                <div class="card" data-aos="fade-up">
                    <a target="_blank" :href="portfolio.url">
                    <h4 class="text-center p-2 isSpecialText">{{portfolio.name }}</h4>
                    <img class="portImage rounded shadow p-3" :src="portfolio.image" :alt="portfolio.name">
                </a>
                </div>
                

            </div>

        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "ourPortfolio",
    data(){
        return{
            portfolios:''
        }
    },
   
    mounted(){
        this.portfolio()
    },
    methods:{
       async portfolio(){
            try{
                const response = await axios.get('../assets/JSON/portfolio.json')
                const data = response.data
                this.portfolios = data.portfolio
                console.log(this.portfolios)

            } catch(error){
                console.log(error);
            }
        }
    }
};
</script>

<style scoped>
.portImage{
    max-width: 100%;
}
.portImage:hover{
    background: linear-gradient(112.1deg, rgba(57, 32, 57, 0.527) 11.4%, rgb(0 0 0 / 42%) 70.2%);
    background-blend-mode: multiply;
}
a{
    text-decoration: none
}
h1{
    color: white;
}
</style>