<template>
  <landingPage />
</template>

<script>
import landingPage from './view/landingPage.vue';
export default {
  name: 'App',
  components:{
    landingPage,
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Shadows+Into+Light&display=swap');

html {
  scroll-behavior: smooth;
}

body{
  height: 100vh;
  background-color: rgb(0, 0, 0);
  font-family: "Archivo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;

}
p{
  color: rgb(119, 118, 118) !important;
}
.main-section {
  background: radial-gradient(circle at 24.1% 68.8%, rgb(19 21 25) 0%, rgb(21 19 19) 99.4%);
  border-radius: 10px;
  animation: borderAnimation 10s infinite;
  width: 90% !important;
  max-width: 100%;
  margin-top: 110px;
}

.text-section {
  color: white;
  background: linear-gradient(112.1deg, rgba(32, 1, 1, 0.644) 11.4%, rgb(13 22 53 / 0%) 70.2%);  border-radius: 10px;
  /*background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(13 22 53 / 0%) 70.2%);  border-radius: 10px;*/

}
.serviceList {
  /*background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(0 0 0 / 42%) 70.2%);*/
  background: linear-gradient(112.1deg, rgba(57, 32, 57, 0.527) 11.4%, rgb(0 0 0 / 42%) 70.2%);

  border-radius: 10px;
  padding: 20px;
  margin-bottom: -50px;
  margin-block: 2px;
}


.logo {
  max-width: 100px;
  height: auto;
}

.isSpecialText{
  color: #307965;
}
.btn {
  border: 3px solid #307965;
  margin: 3px;
  border-radius: 20px;
  color: white;
  animation: borderAnimation 10s infinite;
}

.subtitle{
  font-family: "Shadows Into Light", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.3rem;
}

.bigLogo {
  max-width: 100%;
  width: 100%;
  height: auto;
}

hr {
  background-color: rgb(43, 42, 42);
}

@keyframes borderAnimation {
  0% {
      border-color: #307965;
  }

  25% {
      border-color: #965830;
  }

  50% {
      border-color: #65307e;
  }

  75% {
      border-color: #308096;
  }

  100% {
      border-color: #307965;
  }
}

.loghtcode {
  text-align: center;
  margin-top: 50px;
}



@media (min-width: 768px) and (max-width: 1024px) {
  .loghtcode-img {
      max-width: 70%;
  }
}
</style>