<template>
  <nav class="navbar navbar-expand-lg" data-bs-theme="dark" data-aos="fade-in" id="home">
    <div class="container-fluid">
      <img :src="logo" class="logo" alt="">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02"
        aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarColor02">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link active" href="#">Home
              <span class="visually-hidden">(current)</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#Services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">About</a>
          </li>

        </ul>
        <a href="#Contact" class="btn my-2 my-sm-0" >Contact us</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'navBar',
  data() {
    return {
      logo: '../assets/logo/lightFingers logo white.png',
    }
  },

}
</script>
