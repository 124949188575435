<template>
  <div class="p-4 container-fluid main-section" data-aos="fade-in" id="Contact">
    <div class="row service-section">

      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 hasHover  text-light" >
        <h1 data-aos="fade-up" class="isTitle">Connect With <span class="isSpecialText">Us</span></h1>
        <p data-aos="fade-up">Please fill out the form below to connect with us, or simply scan the QR code located in the footer for faster connection</p>

        <Form @submit="submitForm" :validation-schema="validateForm" class="mt-4" data-aos="fade-up-left">
          <div class="form-group mb-3  ">
            <Field class="form-control" type="text" name="fullName" />
            <ErrorMessage :class="Error" name="fullName" />
          </div>
          <div class="form-group mb-3 ">
            <Field class="form-control" type="email" name="email" />
            <ErrorMessage :class="Error" name="email" />
          </div>
          <div class="form-group mb-3 ">
            <Field class="form-control" type="number" name="phone" />
            <ErrorMessage :class="Error" name="phone" />
          </div>
          <div class="form-group mb-3 ">
            <Field class="form-select" name="service" as="select">
              <option value="Responsive Web Design">Responsive Web Design</option>
              <option value="Frontend Development">Frontend Development</option>
              <option value="Backend Development">Backend Development</option>
              <option value="Landing Pages Creation">Landing Pages Creation</option>
              <option value="E-commerce Solutions">E-commerce Solutions</option>
              <option value="Wordpress Custom Plugins">Wordpress Custom Plugins</option>
              <option value=" Front End Redesign"> Front End Redesign</option>
            </Field>
            <ErrorMessage :class="Error" name="service" />

          </div>
          <div class="form-group mb-3 ">
            <Field class="form-control" as="textarea" name="message" />
            <ErrorMessage :class="Error" name="message" />
          </div>
          <button class="btn">Submit</button>
        </Form>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-section p-3">
        <p data-aos="fade-up">
          At Lifhtfingers, we're passionate about technology and committed to delivering exceptional service to
          our clients. Whether you're exploring partnership opportunities, need technical assistance, or have questions
          about our services, our dedicated team is here to help.

          Feel free to reach out to us using the contact form or via the contact information provided. We value
          your inquiries and strive to provide timely and informative responses to ensure your experience with us is
          productive and satisfying.

          Thank you for considering Lifhtfingers. We look forward to hearing from you and discussing how we can
          collaborate to achieve your goals in the ever-evolving world of technology.
        </p>
      </div>

      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" data-aos="fade-up-right">
        <img :src="code" alt="" class="man">
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toast-notification";
const $toast = useToast();
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup'
export default {
  name: "contactUs",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      Error:'Error',
      validateForm: yup.object({
        fullName: yup.string().required(),
        email: yup.string().email().required(),
        phone: yup.number().positive().required(),
        service: yup.string().required(),
        message: yup.string().required(),

      }),
      servises: [
        "Responsive Web Design",
        "Frontend Development",
        "Backend Development",
        "Landing Pages Creation",
        "E-commerce Solutions",
        "Wordpress Custom Plugins",
        "Front End Redesign",

      ],
      code: "../assets/images/code-snap.png",
    };
  },
  methods: {
   async submitForm(user) {
      console.log(user)
      try {
        const response = await axios.post(
          "http://api24.lightfingers.net/PHPmailer/email.php",
          user
        );
        if (response.request.status === 200 && response.data.success === true) {
          $toast.open({
            message: "Thanks! Message Sent",
            type: "success",
            position: "top-right",
          });
        } else {
          $toast.open({
            message: "Opps! Something went wrong",
            type: "error",
            position: "top-right",
          });
        }
        console.log(response);
      } catch (error) {
        console.error("Error sending form data:", error);
        $toast.open({
          message: "An error occurred while sending the email",
          type: "error",
          position: "top-right",
        });
      }
    }
  }
 
};
</script>

<style scoped>
.form-control,
.form-select {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.serviceList {
  background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(13 22 53) 70.2%);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: -50px;
  margin-block: 2px;
}

.man {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.Error{
  color: rgba(255, 0, 0, 0.795);
}

hr {
  background-color: rgb(43, 42, 42);
}
</style>