<template>
    <div class="container main-hero-content" data-aos="fade-in" >
        <div class="text-center text-light mt-5">
            <h5 class="subtitle">The adept touch of <span class="isSpecialText">light fingers</span> </h5>
            <div class="isLargeText">
                <h1>Crafting digital dreams into reality.<br> The sharper your site, the brighter your success</h1>
                <p>Huray! You have landed on the right page. Guess what? Your next website from us will put you<br>on
                    an
                    extraordinary level to achieve your goals </p>
                <div>
                    <a href="#portfolio" class="btn">Our portfolio</a>
                    <a href="#Contact" class="btn">contact us</a>
                </div>
            </div>
        </div>
        <!-- code imagee -->
        <div class="loghtcode">
            <img class="loghtcode-img" :src="loghtcode" alt="" data-aos="fade-up">
        </div>
    </div>
</template>

<script>
export default {
    name: 'pageHero',
    data() {
        return {
            loghtcode: '../assets/images/lightCode.PNG',
            services: {}
        }
    },
}
</script>




<style scoped>
.main-hero-content {
    background: linear-gradient(112.1deg, rgba(68, 44, 68, 0.301) 11.4%, rgb(0 0 0 / 42%) 70.2%);
    border-radius: 40px;
    border: 2px solid transparent;
    width: 90% !important;
    max-width: 100%;
    animation: borderAnimation 10s infinite;
}

.loghtcode-img {
    max-width: 100%;
    height: auto;
    border-radius: 50px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(207, 204, 204, .05) 0px 5px 10px;
    animation: borderAnimation 10s infinite;
    margin-bottom: -70px;
    border: 10px solid #307965;
    
}

</style>