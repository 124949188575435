<template>
  <div class="moveup">
    <a href="#home"><i class="bi bi-arrow-up-circle-fill" ></i></a> 
</div>
</template>

<script>
export default {
name:'moveTop'
}
</script>

<style scoped>
.moveup {
  font-size: 3rem;
  position: fixed;
  bottom: 20px;
  right: 20px; 
  display: block;
  border: none;
  padding: 15px;
  cursor: pointer;

}

a{
    color: rgb(53, 52, 52);

}
</style>