<template>
  <navBar />
  <pageHero />
  <WhoWeAre />
  <WhatWeDo />
  <ourPortfolio />
  <contactUs />
  <moveTop />
  <pageFooter />
</template>

<script>
import navBar from "../components/navBar.vue";
import pageFooter from "../components/pageFooter.vue";
import pageHero from "../components/pageHero.vue";
import WhoWeAre from "../components/WhoWeAre.vue";
import WhatWeDo from "../components/WhatWeDo.vue";
import contactUs from "../components/contactUs.vue";
import moveTop from "../components/moveTop.vue";
import ourPortfolio from '../components/ourPortfolio.vue'
export default {
  name: "heroSection",
  components: {
    navBar,
    pageFooter,
    pageHero,
    WhoWeAre,
    WhatWeDo,
    contactUs,
    moveTop,
    ourPortfolio
  },
};
</script>
