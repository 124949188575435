<template>
    <div class="p-4 container-fluid main-section" data-aos="fade-in" id="about">
        <div class="row service-section">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 hasHover serviceList text-light" data-aos="fade-up">
                <h3 class="isTitle">Who We <span class="isSpecialText">Are?</span></h3>
                <div v-for="(x, index) in servises" :key="index" data-aos="fade-up">
                    <p class="">
                        <span><i class="bi bi-check2-circle isSpecialText"></i></span> {{ x }}
                    </p>
                    <hr />
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 text-section p-3">
                <h1 data-aos="fade-up">
                    Shaping Tomorrow's <span class="isSpecialText">Tech Today:</span> Meet Light Fingers
                </h1>
                <p data-aos="fade-up">
                    Welcome to Lightfingers.net, where innovation meets precision in the realm of technology. Founded
                    with a vision to redefine boundaries and set new benchmarks in the tech industry, Lightfingers
                    is committed to delivering cutting-edge solutions that empower businesses and individuals alike.

                    At Lightfingers , we believe in harnessing the power of technology to simplify complexities and
                    enhance experiences. Our journey began with a passion for pushing the limits of what's possible,
                    driven by a team of visionary engineers and designers who are dedicated to crafting solutions that
                    make a difference.

                    We specialize in a diverse range of technological domains, including software development,
                    artificial intelligence, cybersecurity, and more. Our expertise extends from conceptualization to
                    implementation, ensuring that every solution we deliver is not only innovative but also practical
                    and impactful.

                    What sets us apart is our relentless pursuit of excellence and our commitment to understanding the
                    unique needs of each client. Whether you're a startup looking to disrupt the market or an
                    established enterprise aiming to optimize operations, we tailor our solutions to fuel your growth
                    and success.
                </p>
                <button data-aos="fade-up" class="btn">Find Out</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "WhoWeAre",
    data() {
        return {
            servises: [
                "Innovative Solutions",
                "Expertise Across Domains",
                "Client-Centric Approach",
                "Culture of Excellence",
                "Vision for the Future",
            ],
            man: "../assets/images/man.png",
        };
    },
};
</script>

